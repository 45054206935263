<template>
    <div class="page-reset-password">
        <LoginMenu />

        <div class="reset-password-container container">
            <div class="reset-password-container__body">
                <h2 class="reset-password-title">Zmena hesla</h2>

                <b-form @submit.prevent="changePassword()" class="reset-password-form">
                    <b-form-group label="Nové heslo*" label-for="new_password_input" :class="{ 'input--error': $v.reset_password.password.$error }">
                        <b-form-input id="new_password_input" v-model="reset_password.password" type="password"></b-form-input>
                        <div class="error-message-form" v-show="$v.reset_password.password.$error">
                            <p v-show="!$v.reset_password.password.required || !$v.reset_password.password.minLength">
                                Heslo musí obsahovať minimálne {{ $v.reset_password.password.$params.minLength.min }} znakov.
                            </p>
                        </div>
                    </b-form-group>

                    <b-form-group label="Potvrdenie nového hesla*" label-for="new_password_confirmation_input" :class="{ 'input--error': $v.reset_password.password_confirmation.$error }">
                        <b-form-input id="new_password_confirmation_input" v-model="reset_password.password_confirmation" type="password"></b-form-input>
                        <div class="error-message-form" v-show="$v.reset_password.password_confirmation.$error">
                            <p v-show="!$v.reset_password.password_confirmation.required || !$v.reset_password.password_confirmation.sameAs">
                                Heslá sa musia zhodovať.
                            </p>
                        </div>
                    </b-form-group>

                    <b-button type="submit" variant="primary-lighter" class="send-button">
                        <BeatLoader v-if="spinners.post_data" />
                        <span v-else>ZMENIŤ HESLO</span>
                    </b-button>

                    <ResponseSuccess v-if="response_type.success" :success="response_type.success" />
                    <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config";
import LoginMenu from "@/components/login/LoginMenu";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ResponseError from "@/components/ResponseError";
import ResponseSuccess from "@/components/ResponseSuccess";
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
    name: "PageResetPassword",
    metaInfo() {
        return {
            title: 'Zmena hesla | ',
        }
    },
    components: {
        LoginMenu,
        BeatLoader,
        ResponseSuccess,
        ResponseError,
    },
    data() {
        return {
            reset_password: {
                reset_token: '',
                password: '',
                password_confirmation: '',
            },

            spinners: {
                post_data: false,
            },
            response_type: {
                success: '',
                failed: null,
            },
        }
    },
    mounted() {
        if (this.$route.query.reset_token) {
            this.reset_password.reset_token = this.$route.query.reset_token
        } else {
            this.$router.replace({ name: 'PageLogin' })
        }
    },
    methods: {
        changePassword() {
            this.$v.$touch()
            if (this.$v.$invalid) return false

            const postData = new FormData();
            postData.set('reset_token', this.reset_password.reset_token);
            postData.set('password', this.reset_password.password);
            postData.set('password_confirmation', this.reset_password.password_confirmation);

            this.postLoginUser(postData)
        },
        postLoginUser(postData) {
            this.resetResponseType()
            if (this.spinners.post_data) return false
            this.spinners.post_data = true

            // for (let pair of postData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }

            this.axios.post(config.API_URL + '/auth/change-password', postData, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.response_type.success = responseData.data.message
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.post_data = false
                });
        },
        resetResponseType() {
            this.response_type.failed = null
            this.response_type.success = ''
        },
    },
    validations: {
        reset_password: {
            password: {
                required,
                minLength: minLength(6)
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.page-reset-password::v-deep {
    background-color: $body-gray;
    min-height: 100vh;
    position: relative;

    .reset-password-container {
        @extend %flex-column-center;
        justify-content: center;
        position: relative;
        padding-top: em(100);

        &__body {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .reset-password-title {
                margin-bottom: em(10);
            }

            .reset-password-form {
                margin-top: em(100);
                width: 100%;
                max-width: em(400);
            }

            .send-button {
                min-width: em(200, 16);
            }
        }
    }

    @media (max-width: 991px) {
        .reset-password-container {
            padding-top: em(50);

            &__body {
                .reset-password-form {
                    margin-top: em(30);
                }
            }
        }
    }
}
</style>